import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import 'swiper/css/swiper.css'

import { goodsSkuInfo, addGoodsbrowse, goodsSkuPage } from "@/api/goods/goods"
import { goodsGetSizeGuied } from "@/api/pc"

import {
    calculate,
} from "@/api/order/payment"

import { getToken } from "@/utils/auth"
import { addToCart } from "@/api/goods/cart"

import { goodsSkuDetail } from "@/api/seckill"

import { addressList } from "@/api/member/member"

export default {
    name: "productDetails",
    components: {
        Swiper,
        SwiperSlide
    },
    data: () => {
        return {
            // 翻译代码
            // translateCode: 'en',
            loginShow: false,
            num: 1,
            descriptionList: [],
            sizeList: [
                {
                    size: "XS",
                    length: "50",
                    sleeveLength: "50",
                    bust: "50",
                    waistSize: "50",
                    hipSize: "50"
                }, {
                    size: "S",
                    length: "60",
                    sleeveLength: "60",
                    bust: "60",
                    waistSize: "60",
                    hipSize: "60"
                }, {
                    size: "M",
                    length: "70",
                    sleeveLength: "70",
                    bust: "70",
                    waistSize: "70",
                    hipSize: "70"
                }, {
                    size: "L",
                    length: "88",
                    sleeveLength: "88",
                    bust: "88",
                    waistSize: "88",
                    hipSize: "88"
                }, {
                    size: "XL",
                    length: "102",
                    sleeveLength: "102",
                    bust: "102",
                    waistSize: "102",
                    hipSize: "102"
                },
            ],
            returnsList: [
                "Sign in your Soyego account.",
                "Find the order in My orders, click “Customer Service”",
                "Select the item(s) you would like to return, select the reason, and submit.",
                "A return address will be shown after we process the return. ",
                "Send the items to the provided address."
            ],
            images: [],
            swiperOptionTop: {
                loop: true,
                loopedSlides: 7,
                slidesPerView: "auto",
                
                // spaceBetween: 10,   //间距
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },
                direction: "vertical"
            },
            swiperOptionThumbs: {
                loop: true,
                loopedSlides: 7,
                slidesPerView: "auto",
                touchRatio: 0.2,
                slideToClickedSlide: true,
                direction: "vertical"
            },
            swiperOption: {
                slidesPerView: 2,
                spaceBetween: 10,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '.usa-swiper-button-next',
                    prevEl: '.usa-swiper-button-prev'
                },
            },
            productDetail: {
                strCurXSoyego: localStorage.getItem("strCurXSoyego"),
                countrySoyego: localStorage.getItem('countrySoyego'),
                goods_name: '',
                market_price: 0,
                discount_price: 0,
                save: 0,
                sku_no: '',
                colorList: [],
                colorAtive: 0,
                colorAtiveName: '',
                sizeData: Object,
                sizeAtive: 0,
                sizeAtiveName: '',
                delivery_money: 0,
                num: 0,
                imgUrl: '',
                sku_id: 0,
                site_id: 0,
                verify_state: 0,
                goods_id: 0,
                cart_id: 0,
                sizeDataList: [],
                sizeDataListIn: []
            },
            countrySoyego: localStorage.getItem('countrySoyego'),
            strCurXSoyego: localStorage.getItem("strCurXSoyego"),
            ids: 0,
            toBag: {
                sku_id: 0,
                site_id: 0,
                cart_id: 0
            },
            likeList: [],
            loadLikeing: true,
            loadDetail: true,
            listAddress: [],
            buyNow: false,
            leftImgIndex: 0,
            navBannerAtive: 0,
            transformX: 0,
            colorIndex: 0,
            sizeIndex: 0,
            detailName: localStorage.getItem("productDetailsCategoryNameSoyego") || "",
            mainTextAtive: "2",
            categoryId: -1,
            // IN或CM 0|1
            goodsSizeData: 0,
            // 需要翻译的静态文字
            translateTextData: {
                IN: 'IN',
                CM: 'CM',
                Color: 'Color',
                Size: 'Size',
                Quantity: 'Quantity',
                Description: 'Description',
                Size_Fit: 'Size & Fit',
                Returns: 'Returns',
                About_this_item: 'About this item',
                Description: 'Description',
                Add_to_bag: 'Add to bag',
                BUY_NOW: 'BUY NOW',
                Shopping_security: 'Shopping security',
                Secure_Payment: 'Secure Payment',
                Safe_Logistics: 'Safe Logistics',
                Custome_Service: 'Customer Service',
                Privacy_Protecion: 'Privacy Protecion',
                Share: 'Share',
                You_may_also_like: 'You may also like',
                View_More: 'View More'
            }
        }
    },
    watch: {
        $route: "fetchData",
        num: {
            deep: true,
            handler: function (val) {
                this.num = val;
                this.productDetail.num = val;
                // this.fetchData();
            },
        },
    },
    created() {
        this.ids = this.$route.query.sku_id;
        this.getDetails();
        // this.getGoodsLike(false);
    },
    mounted() {
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.$swiper;
            const swiperThumbs = this.$refs.swiperThumbs.$swiper;
            swiperThumbs.controller.control = swiperTop;
            swiperTop.controller.control = swiperThumbs;
        });
    },
    // 加载完毕执行
    updated() {
        this.getTranslateText();
        this.transReturns();
    },
    computed: {

    },
    methods: {
        // 翻译静态文本
        getTranslateText() {
            let strText = '';
            let i = 0;
            let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
            translateData.forEach((item,index) => {
                strText += item + '\n'
            })
            // 翻译文本
            this.$fanyi.translateData({
                query: strText,
                success: resData => {
                    for(let key in this.translateTextData){
                        this.translateTextData[key] = resData[i];
                        i++;
                    }
                }
            })
        },
        // 翻译 Returns
        transReturns() {
            let strText = '';
            this.returnsList.forEach(item => {
                strText += item + '\n'
            })
            // 翻译文本
            this.$fanyi.translateData({
                query: strText,
                success: resData => {
                    this.returnsList.forEach((item,index) => {
                        this.returnsList[index] = resData[index];
                    })
                }
            })
        },
        colorClick(item, index) {
            this.ids = item.sku_id;
            this.colorIndex = index;
            let that = this;
            this.getDetails();
            // this.colorMainAtive(item);
            // this.getDetails().then(()=>{
            //     that.colorMainAtive(item);
            // });
        },
        colorMainAtive(item) {
            this.productDetail.id = this.ids;
            this.productDetail.colorAtive = item.sku_id;
            this.productDetail.colorAtiveName = item.spec_value_name;
        },
        sizeClick(item, index) {
            this.ids = item.sku_id;
            this.sizeIndex = index;
            let that = this;
            this.getDetails();
            // this.sizeMainAtive(item);
            // this.getDetails().then(() =>{
            //     that.sizeMainAtive(item);
            // });
        },
        sizeMainAtive(item) {
            this.productDetail.id = this.ids;
            this.productDetail.sizeAtive = item.sku_id;
            this.productDetail.sizeAtiveName = item.spec_value_name;
        },
        prevClick() {
            if(this.navBannerAtive - 1 < 0){
                this.navBannerAtive = this.images.length - 1;
            }else{
                this.navBannerAtive = this.navBannerAtive - 1;
            }
            if(this.navBannerAtive > 7) {
                let width = -((this.navBannerAtive - 7) * 97);
                this.transformX = width;
            }else if(this.navBannerAtive <= 0) {
                this.transformX = 0;
            }
            this.$refs.navBanner.prev();
        },
        nextClick() {
            if(this.navBannerAtive + 1 >= this.images.length){
                this.navBannerAtive = 0
            }else{
                this.navBannerAtive = this.navBannerAtive + 1;
            }
            if(this.navBannerAtive > 7) {
                let width = -((this.navBannerAtive - 7) * 97);
                this.transformX = width;
            }else if(this.navBannerAtive <= 0) {
                this.transformX = 0;
            }
            this.$refs.navBanner.next();
        },
        navBannerChange(index) {
            this.navBannerAtive = index;
            if(this.navBannerAtive > 7) {
                let width = -((this.navBannerAtive - 7) * 97);
                this.transformX = width;
            }else if(this.navBannerAtive <= 0) {
                this.transformX = 0;
            }
            this.$refs.navBanner.setActiveItem(index);
        },
        handleClose() {
            this.loginShow = false;
        },
        detailsClick(id) {
            localStorage.setItem('productDetailsDropSoyego', false);
            this.$router.push({ path: '/productDetails', query: { sku_id: id } });
        },
        // 每日推荐
        getGoodsLike(like) {
            this.loadLikeing = true;
            const params = {
                page: 1,
                page_size: 8,
                attr_rand: true,
                category_id: this.categoryId,
                barterRate: this.countrySoyego,
            };
            goodsSkuPage(params || {}).then(res => {
                if (res.code == 0 && res.data) {
                    let data = res.data;
                    let strText = '';
                    data.list.forEach(item => {
                        item.goods_image = item.goods_image.split(',');
                        item.discount_price = Number(item.discount_price).toFixed(2);
                        strText += item.goods_name + '\n';
                    });
                    if(like){
                        this.likeList = this.likeList.concat(data.list);
                    }else{
                        this.likeList = data.list;
                    }
                    // 翻译
                    this.$fanyi.translateData({
                        query: strText,
                        success: resData => {
                            // like true加载更多数据 以后可能会出现异步，有空优化; false 第一页数据
                            if(like){
                                data.list.forEach((item,index) => {
                                    data.list[index].goods_name = resData[index];
                                })
                                this.likeList = this.newInList.concat(data.list);
                            }else{
                                this.likeList.forEach((item,index) => {
                                    this.likeList[index].goods_name = resData[index];
                                })
                            }
                        }
                    })

                    this.loadLikeing = false;
                }
            }).catch(err => {
                this.$message.error(err.message);
                this.loadLikeing = false;
            });
        },
        // addFootprint(){
        //     let token = getToken();
        //     if(token != undefined){
        //         addGoodsbrowse({ token: token, sku_id: this.ids });
        //         addressList({
        //             page_size: 0
        //         }).then(res => {
        //             console.log(res)
        //             if (res.code == 0 && res.data.list) {
        //                 res.data.list.forEach( item =>{
        //                     if(item.is_default == 1){
        //                         this.freightId = item.id;
        //                         console.log(this.freightId)
        //                     }
        //                 })
        //             }
        //         })
        //     }
        // },
        fetchData() {
            // this.addFootprint();
            this.ids = this.$route.query.sku_id;
            this.loadDetail = true;
            this.loadLikeing = true;
            this.getDetails();
            // this.getGoodsLike(false);
        },
        getGoodsSkuDetail() {
            let that = this;
            this.productDetail.num = this.num;
            goodsSkuInfo({
                sku_id: this.ids,
                member_address: JSON.stringify(this.listAddress),
                barterRate: this.countrySoyego
                // district_id: this.freightId
            }).then(res => {
                if (res.code == 0 && res.data) {
                    let data = res.data;

                    let id = data.category_id.split(',');
                    if(id[id.length - 1] != "" && id[id.length - 1] != undefined){
                        this.categoryId = id[id.length - 1];
                    }else{
                        this.categoryId = id[id.length - 2];
                    }

                    this.toBag.sku_id = data.sku_id;
                    this.toBag.site_id = data.site_id;
                    this.toBag.cart_id = data.goods_id;

                    data.goods_attr_format = JSON.parse(data.goods_attr_format);
                    data.goods_image = data.goods_image.split(",");
                    data.goods_spec_format = JSON.parse(data.goods_spec_format);

                    this.images = data.goods_image;
                    // this.swiperOptionTop.loopedSlides = this.images.length;
                    // this.swiperOptionThumbs.loopedSlides = this.images.length;

                    this.productDetail.goods_id = data.goods_id;
                    this.productDetail.goods_name = data.goods_name;
                    this.productDetail.sku_id = data.sku_id;
                    this.productDetail.site_id = data.site_id;
                    this.productDetail.cart_id = data.goods_id;
                    this.productDetail.verify_state = data.verify_state;
                    this.productDetail.imgUrl = this.images[0];

                    this.productDetail.sku_no = data.sku_no;
                    this.productDetail.market_price = data.market_price <= 0 ? data.price : data.market_price;
                    // this.productDetail.price = data.price;
                    this.productDetail.discount_price = Number(data.discount_price).toFixed(2);
                    this.productDetail.delivery_money = data.delivery_money;
                    this.productDetail.delivery_start_day = data.delivery_start_day;
                    this.productDetail.delivery_end_day = data.delivery_end_day;
                    let save = (data.market_price - data.discount_price).toFixed(2);
                    if (save < 0) {
                        this.productDetail.save = (data.price - data.discount_price).toFixed(2);
                    } else {
                        this.productDetail.save = (data.market_price - data.discount_price).toFixed(2);
                    }
                    data.goods_spec_format.forEach(item => {
                        if (item.spec_name == "Colour" || item.spec_name == "Color") {
                            this.productDetail.colorList = item.value;
                            this.productDetail.colorAtive = item.value[this.colorIndex].sku_id;
                            this.productDetail.colorAtiveName = item.value[this.colorIndex].spec_value_name;
                        }
                        if (item.spec_name == "Size") {
                            this.productDetail.sizeData = item.value;
                            this.productDetail.sizeAtive = item.value[this.sizeIndex].sku_id;
                            this.productDetail.sizeAtiveName = item.value[this.sizeIndex].spec_value_name;
                        }
                    });
                    
                    that.getSizeList(data.goods_id);
                    this.descriptionList = data.goods_attr_format;
                    // 翻译 描述
                    let attr_name = '',
                        value_name = '';
                    data.goods_attr_format.forEach(item => {
                        attr_name += item.attr_name + '\n'
                        value_name += item.attr_value_name + '\n' 
                    })
                    // 翻译 attr_name
                    this.$fanyi.translateData({
                        query: attr_name,
                        success: resData => {
                            this.descriptionList.forEach((item,index) => {
                                this.descriptionList[index].attr_name = resData[index];
                            })
                        }
                    })
                    // 翻译 value_name
                    this.$fanyi.translateData({
                        query: value_name,
                        success: resData => {
                            this.descriptionList.forEach((item,index) => {
                                this.descriptionList[index].attr_value_name = resData[index];
                            })
                        }
                    })
                    
                    // // 翻译商品名称
                    this.$fanyi.translateData({
                        query: this.productDetail.goods_name,
                        success: resData => {
                            this.productDetail.goods_name = resData
                        }
                    })
                    // 翻译商品颜色
                    let strColorList = '';
                    this.productDetail.colorList.forEach(item => {
                        strColorList += item.spec_value_name + '\n'
                    })
                    this.$fanyi.translateData({
                        query: strColorList,
                        success: resData => {
                            this.productDetail.colorList.forEach((item,index) => {
                                this.productDetail.colorList[index].spec_value_name = resData[index];
                            })
                        }
                    })

                    this.loadDetail = false;
                    this.getGoodsLike(false);
                }
            }).catch(err => {
                this.$message.error(err.message);
                this.$router.push("/");
                this.loadDetail = false;
            })
        },
        getDetails() {
            let detail = localStorage.getItem("productDetailsDropSoyego");
            let token = getToken();
            if(token != undefined){
                addGoodsbrowse({ token: token, sku_id: this.ids });
                addressList({
                    page_size: 0
                }).then(res => {
                    // console.log(res)
                    if (res.code == 0 && res.data.list) {
                        res.data.list.forEach( item =>{
                            if(item.is_default == 1){
                                this.listAddress = item;
                            }
                        })
                        if(detail == "true"){
                            this.getDrop();
                        }else{
                            this.getGoodsSkuDetail();
                        }
                    }
                })
            }else{
                if(detail == "true"){
                    this.getDrop();
                }else{
                    this.getGoodsSkuDetail();
                }
            }
            
        },
        getDrop() {
            this.productDetail.num = this.num;
            goodsSkuDetail({ 
                seckill_id: this.ids,
                site_id: 0,
                barterRate: this.countrySoyego,
                member_address: JSON.stringify(this.listAddress)
            }).then( res =>{
                if (res.code == 0 && res.data) {
                    // let data = res.data.goods_sku_detail;
                    let data = res.data.goods_sku_detail;
                    this.toBag.sku_id = data.sku_id;
                    this.toBag.site_id = data.site_id;
                    this.toBag.cart_id = data.goods_id;

                    data.goods_attr_format = JSON.parse(data.goods_attr_format);
                    data.goods_image = data.goods_image.split(",");
                    data.goods_spec_format = JSON.parse(data.goods_spec_format);

                    this.images = data.goods_image;
                    // this.swiperOptionTop.loopedSlides = this.images.length;
                    // this.swiperOptionThumbs.loopedSlides = this.images.length;

                    this.productDetail.goods_id = data.goods_id;
                    this.productDetail.goods_name = data.goods_name;
                    this.productDetail.sku_id = data.sku_id;
                    this.productDetail.site_id = data.site_id;
                    this.productDetail.cart_id = data.goods_id;
                    this.productDetail.verify_state = data.verify_state;
                    this.productDetail.imgUrl = this.images[0];

                    this.productDetail.sku_no = data.sku_no;
                    this.productDetail.market_price = data.price;
                    // this.productDetail.price = data.price;
                    this.productDetail.discount_price = data.seckill_price;
                    this.productDetail.delivery_money = data.delivery_money;
                    this.productDetail.save = (data.price - data.seckill_price).toFixed(2);

                    data.goods_spec_format.forEach(item => {
                        if (item.spec_name == "Colour" || item.spec_name == "Color") {
                            this.productDetail.colorList = item.value;
                            this.productDetail.colorAtive = item.value[this.colorIndex].sku_id;
                            this.productDetail.colorAtiveName = item.value[this.colorIndex].spec_value_name;
                        }
                        if (item.spec_name == "Size") {
                            this.productDetail.sizeData = item.value;
                            this.productDetail.sizeAtive = item.value[this.sizeIndex].sku_id;
                            this.productDetail.sizeAtiveName = item.value[this.sizeIndex].spec_value_name;
                        }
                    });
                    
                    // that.getSizeList(data.goods_id);

                    this.descriptionList = data.goods_attr_format;
                    this.loadDetail = false;
                    this.getGoodsLike(false);
                }
            }).catch( err => {
                this.$message.error(err.message)
                this.loadDetail = false;
                this.$router.push("/");
            })
        },
        getSizeList(id) {
            goodsGetSizeGuied({ goods_id: id }).then(res => {
                if (res.code == 0) {
                    this.productDetail.sizeDataList = res.data;
                    // CM换算IN
                    let inArr = JSON.parse(JSON.stringify(res.data.cm));
                    inArr.forEach((item, index) => {
                        for(let key in item){
                            if(!isNaN(Number(item[key]))){
                                inArr[index][key] = Math.round(item[key] * 0.39);
                            }else if(item[key].indexOf('-') > -1){
                                let inSplit = item[key].split('-');
                                if(!isNaN(Number(inSplit[0]))){
                                    inArr[index][key] = Math.round(inSplit[0] * 0.39) + '-' + Math.round(inSplit[1] * 0.39);
                                }
                            }
                        }
                    });
                    this.sizeDataListIn = inArr;
                    // 翻译header title
                    let strText = '';
                    this.productDetail.sizeDataList.title.forEach(item => {
                        strText += item + '\n';
                    })
                    // 翻译
                    this.$fanyi.translateData({
                        query: strText,
                        success: resData => {
                            this.productDetail.sizeDataList.title.forEach((item,index) => {
                                this.productDetail.sizeDataList.title[index] = resData[index];
                            })
                        }
                    })
                }
            }).catch(err => {
                // console.log(res)
            })
        },
        buyNowClick() {
            let token = getToken();
            if(token == undefined){
                this.buyNow = true;
                this.loginShow = true;
            }else{
                this.productDetail.num = this.num;
                this.productDetail.imgUrl = this.images[0];
                this.productDetail.id = this.ids;
                // console.log(this.productDetail)
                localStorage.setItem('shoppProductSoyego', JSON.stringify(this.productDetail));
                this.$router.push({ path: '/shoppPay', query: { data: "buy" } })
            }            
        },
        addToBag() {
            let token = getToken();
            if(token == undefined){
                if(localStorage.getItem("cartProductsSoyego") == null || localStorage.getItem("cartProductsSoyego") == undefined){
                    let list = [];
                    list.push(this.productDetail);
                    localStorage.setItem('cartProductsSoyego', JSON.stringify(list));
                    this.$message.success('Added successfully');
                }else{
                    let list = JSON.parse(localStorage.getItem("cartProductsSoyego"));
                    list.push(this.productDetail);
                    localStorage.setItem('cartProductsSoyego', JSON.stringify(list));
                    this.$message.success('Added successfully');
                }
                // this.$router.push({ path: '/login' })
            }else{
                addToCart({
                    num: this.num,
                    sku_id: this.toBag.sku_id,
                    site_id: this.toBag.site_id,
                    cart_id: this.toBag.cart_id,
                }).then( res => {
                    if(res.code == 0 && res.data){
                        this.$message.success('Added successfully');
                    }
                }).catch( err => {
                    this.$message.error('Add failed');
                })
            }
        },
        // 切换商品规格
        getInSizeData(data) {
            if(data == 'IN') this.goodsSizeData = 0;
            if(data == 'CM') this.goodsSizeData = 1;

        }
    },
    // destroyed() {
    //     console.log("销毁")
    //     localStorage.removeItem("productDetailsSoyego");
    // }
}
