<template>
    <div class="productDetails">
        <div class="warp">
            <div class="productDetails-title">
                <!-- <span>Home</span>&nbsp;/&nbsp;<span>Furniture</span>&nbsp;/&nbsp;
                <span>Crystal Air Vallage Bed</span> -->
                <span>{{detailName}}</span>
            </div>
            <div class="productDetails-main" v-loading="loadDetail">
                <div class="main-left">
                    <div class="main-nav">
                        <div class="nav-bottom">
                             <!-- class="border" -->
                            <div class="bottom" :style="'transform:translateY('+transformX+'px)'">
                                <div v-for="(item, index) in images" :key="index" @click="navBannerChange(index)" :class="navBannerAtive == index ? 'border' : ''">
                                    <div class="img">
                                        <el-image :lazy-src="item" :src="item" fit="cover" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="nav-top">
                            <div class="nav-arrow">
                                <div class="prev" @click="prevClick()"><el-image :src="require('@/assets/images/index/ddps/＜.png')"></el-image></div>
                                <div class="next" @click="nextClick()"><el-image :src="require('@/assets/images/index/ddps/＞.png')"></el-image></div>
                            </div> 
                            <el-carousel :autoplay="false" arrow="never" style="width: 100%;" height="720px" indicator-position="none" ref="navBanner">
                                <el-carousel-item v-for="(item, index) in images" :key="index" style="width: 100%;height: 100%;">
                                    <el-image :lazy-src="item" :src="item" fit="cover" style="width: 100%;height: 100%;"></el-image>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <!-- <div class="nav-left">
                            <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                                <swiper-slide v-for="(item, index) in images" :key="index">
                                    <el-image :lazy-src="item" :src="item" fit="contain" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                                </swiper-slide>
                            </swiper>
                            <div>
                                <div class="swiper-button-next swiper-button-white" slot="button-next" @click="nextBtnClick()">
                                    <img src="@/assets/images/goods/next.png" />
                                </div>
                                <div class="swiper-button-prev swiper-button-white" slot="button-prev" @click="prevBtnClick()">
                                    <img src="@/assets/images/goods/prev.png" />
                                </div>
                            </div>
                        </div>
                        <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                            <swiper-slide v-for="(item, index) in images" :key="index">
                                <el-image :lazy-src="item" :src="item" fit="contain" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                            </swiper-slide>
                        </swiper> -->
                    </div>
                    <div class="main-text">
                        <h5>{{translateTextData.About_this_item}}</h5>
                        <el-collapse accordion v-model="mainTextAtive">
                            <el-collapse-item :title="translateTextData.Description" name="1">
                                <div class="description" v-for="(item, index) in descriptionList" :key="index">
                                    <span>{{ item.attr_name }}</span>
                                    <span>{{ item.attr_value_name }}</span>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item :title="translateTextData.Size_Fit" name="2">
                                <div class="size">
                                    <div class="btn">
                                        <span :class="goodsSizeData==0?'cur':''" @click="getInSizeData('IN')">{{translateTextData.IN}}</span>
                                        <span :class="goodsSizeData==1?'cur':''" @click="getInSizeData('CM')">{{translateTextData.CM}}</span>
                                    </div>
                                    <div class="table">
                                        <div class="header">
                                            <div class="tr">
                                                <div v-for="(item, index) in productDetail.sizeDataList.title" :key="index">
                                                    <span>{{item}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- IN 规格 -->
                                        <div class="body" v-if="goodsSizeData == 0">
                                            <div class="tr" v-for="(in_item, in_index) in sizeDataListIn" :key="in_index">
                                                <div v-for="(item2, index2) in in_item" :key="index2">
                                                    <span>{{ item2 }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- CM规格 -->
                                        <div class="body" v-if="goodsSizeData == 1">
                                            <div class="tr" v-for="(item, index) in productDetail.sizeDataList.cm" :key="index">
                                                <div v-for="(item1, index1) in item" :key="index1">
                                                    <span>{{ item1 }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item :title="translateTextData.Returns" name="3">
                                <div class="returns">
                                    <p v-for="(item, index) in returnsList" :key="index">{{ index + 1 + "." + item }}</p>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
                <div class="main-right">
                    <div class="main-title">
                        <div class="text">
                            <p>{{ productDetail.goods_name }}</p>
                            <img src="@/assets/images/newIn/wish.png" />
                        </div>
                        <span>ID: {{ productDetail.goods_id }}</span>
                        <div class="price">
                            <!-- <span :style="productDetail.discount_price - productDetail.market_price < 0 ? 'color: #FF5656' : 'color: #330000'">{{ productDetail.strCurXSoyego }}{{ productDetail.discount_price }}</span> -->
                            <!-- <s v-if="productDetail.discount_price - productDetail.market_price < 0">{{ productDetail.strCurXSoyego }}{{ productDetail.market_price }}</s> -->
                            <span :style="productDetail.save > 0 ? 'color: #FF5656' : 'color: #330000'">{{ productDetail.strCurXSoyego }}{{ productDetail.discount_price }}</span>
                            <s v-if="productDetail.save > 0">{{ productDetail.strCurXSoyego }}{{ productDetail.market_price }}</s>
                        </div>
                        <p v-if="productDetail.save > 0">Save {{ productDetail.strCurXSoyego }}{{ productDetail.save }}</p>
                    </div>
                    <div class="main-color" v-if="productDetail.colorList">
                        <p>{{translateTextData.Color}}:<span>{{productDetail.colorAtiveName}}</span></p>
                        <div>
                            <!-- <div @click="productDetail.colorAtive = item.sku_id;productDetail.colorAtiveName = item.spec_value_name;" v-for="(item, index) in productDetail.colorList" :key="index" :class="productDetail.colorAtive == item.sku_id ? 'outer-round round-ative' : 'outer-round'">
                                <div :class="'round round-' + item.spec_value_name"></div>
                            </div> -->
                            <div @click="colorClick(item, index)" v-for="(item, index) in productDetail.colorList" :key="index" :class="productDetail.colorAtive == item.sku_id ? 'colorItem border' : 'colorItem'">
                                <el-image v-if="item.image && item.image != ''" :lazy-src="item.image" :src="item.image" fit="cover" style="cursor: pointer;margin-right: 12px;width: 32px;height: 32px;"></el-image>
                                <span>{{ item.spec_value_name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="main-size" v-if="productDetail.sizeAtive">
                        <p>{{translateTextData.Size}}: <span>{{productDetail.sizeAtiveName}}</span></p>
                        <div class="size">
                            <span @click="sizeClick(item, index)" :class="productDetail.sizeAtiveName == item.spec_value_name ? 'ative' : ''" v-for="(item, index) in productDetail.sizeData" :key="index">{{item.spec_value_name}}</span>
                        </div>
                    </div>
                    <div class="main-quantity">
                        <p>{{translateTextData.Quantity}}</p>
                        <el-input-number v-model="num" :min="1"></el-input-number>
                    </div>
                    <div class="btn">
                        <button @click="addToBag()">{{translateTextData.Add_to_bag}}</button>
                        <button @click="buyNowClick()">{{translateTextData.BUY_NOW}}</button>
                    </div>
                    <!-- <div class="usa">
                        <h5>Shipping to USA</h5>
                        <div class="usa-title">
                            <div class="img"><img src="@/assets/images/newIn/delivery.png" /></div>
                            <span>Delivery</span>
                            <div class="img"><img src="@/assets/images/newIn/？.png" /></div>
                        </div>
                        <swiper class="swiper" :options="swiperOption" ref="mySwiper" style="margin: 0;width: 70%;">
                            <swiper-slide>
                                <div class="swiper-main">
                                    <div class="price">
                                        <p>Express Shipping:<b> {{productDetail.countrySoyego}}{{productDetail.strCurXSoyego}}{{productDetail.delivery_money}}</b>, Free on orders <span>US$99.00+</span></p>
                                    </div>
                                    <div class="time">
                                        <p>Shipping Info</p>
                                        <p><b>{{ productDetail.delivery_start_day }} - {{ productDetail.delivery_end_day }}</b> day</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="swiper-main">
                                    <div class="price">
                                        <p>Express Shipping:<b> US$5.90</b>, Free on orders <span>US$59.00+</span></p>
                                    </div>
                                    <div class="time">
                                        <p>Expected to be delived on</p>
                                        <p>09/01/2022 - 15/01/2022</p>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="swiper-main">
                                    <div class="price">
                                        <p>Express Shipping:<b> US$5.90</b>, Free on orders <span>US$59.00+</span></p>
                                    </div>
                                    <div class="time">
                                        <p>Expected to be delived on</p>
                                        <p>09/01/2022 - 15/01/2022</p>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-pagination" slot="pagination"></div>
                        <div class="usa-swiper-button-prev" slot="button-prev">
                            <div class="img">
                                <img src="@/assets/images/newIn/z.png" />
                            </div>
                        </div>
                        <div class="usa-swiper-button-next" slot="button-next">
                            <div class="img">
                                <img src="@/assets/images/newIn/r.png" />
                            </div>
                        </div>
                    </div> -->
                    <div class="main-security">
                        <div class="security-title">
                            <div class="img"><img src="@/assets/images/newIn/safe.png" /></div>
                            <span>{{translateTextData.Shopping_security}}</span>
                            <div class="img"><img src="@/assets/images/newIn/？.png" /></div>
                        </div>
                        <div class="text">
                            <span>{{translateTextData.Secure_Payment}}</span>
                            <span>{{translateTextData.Safe_Logistics}}</span>
                        </div>
                        <div class="text">
                            <span>{{translateTextData.Custome_Service}}</span>
                            <span>{{translateTextData.Privacy_Protecion}}</span>
                        </div>
                    </div>
                    <div class="main-share">
                        <h5>{{translateTextData.Share}}</h5>
                        <div class="share-text">
                            <div>
                                <div class="img"><img src="@/assets/images/newIn/facebook.png" /></div>
                                <span>Fackbook</span>
                            </div>
                            <div>
                                <div class="img"><img src="@/assets/images/newIn/twitter.png" /></div>
                                <span>Twitter</span>
                            </div>
                            <div>
                                <div class="img"><img src="@/assets/images/newIn/形状 15.png" /></div>
                                <span>Pin it</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-like" v-loading="loadLikeing">
                <div class="warp">
                    <h5>{{translateTextData.You_may_also_like}}</h5>
                    <div class="list">
                        <div class="item" v-for="(item, index) in likeList" :key="index">
                            <div class="img" @click="detailsClick(item.sku_id)">
                                <el-image :lazy-src="item.goods_image[0]" :src="item.goods_image[0]" fit="cover" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                            </div>
                            <span>{{ item.goods_name }}</span>
                            <p>{{ strCurXSoyego }}{{ item.discount_price }}</p>
                        </div>
                    </div>
                    <div class="btn">
                        <button @click="getGoodsLike(true)">{{translateTextData.View_More}}</button>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="loginShow" @closed="handleClose" top="10vh" width="55vw" class="login-dialog">
            <LoginComponent :buyNow="buyNow" @close="buyNowClick"></LoginComponent>
        </el-dialog>
    </div>
</template>

<style>
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{content: '';}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{content: '';}
.login-dialog .el-dialog__header{ padding: 0; }
.login-dialog .el-dialog__body{ padding: 0; }
.login-dialog .el-dialog__body .login{ margin: 0; }
.login-dialog .el-dialog__body .login .login-main{ width: calc(50% - 80px);padding: 60px 40px 0; }
.login-dialog .el-dialog__body .login .login-main .main-sign > div:first-child{ padding-top: 0; }
.login-dialog .el-dialog__body .login .login-main .main-sign .agreement p{ width: 90%; }

.about-content {
    background: #f7f9fa;
}
.productDetails .productDetails-main .el-collapse .el-collapse-item__header {
    font-size: 14px;
    font-family: Montserrat,SF-UI;
    font-weight: bold;
    color: #330000;
    padding-left: 10px;
}
.productDetails .productDetails-main .el-collapse .el-collapse-item__arrow {
    font-weight: bold;
    color: #330000;
}

.productDetails .productDetails-main .el-input-number {
    width: 7vw;
}
.productDetails .productDetails-main .el-input-number__increase,
.productDetails .productDetails-main .el-input-number__decrease {
    width: 28px;
    background: transparent;
    border-radius: 0;
}
.productDetails .productDetails-main .el-input__inner {
    height: 40px;
    line-height: 40px;
    border-radius: 0;
}
.productDetails .productDetails-main .el-input-number .el-input__inner {
    padding-left: 30px;
    padding-right: 30px;
}
.productDetails .productDetails-main .el-input-number__increase:hover,
.productDetails .productDetails-main .el-input-number__decrease:hover {
    color: #330000;
}
.productDetails .productDetails-main .el-input-number__increase:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled),
.productDetails .productDetails-main .el-input-number__decrease:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled) {
    border-color: #330000;
}

.productDetails .productDetails-main .el-checkbox__inner:hover {
    border-color: #330000;
}
.productDetails .productDetails-main .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #330000;
    border-color: #330000;
}
</style>

<style lang="scss" scoped>
@import "./productDetails/productDetails.scss";
</style>

<script>
import productDetails from "./productDetails/productDetails.js"
import LoginComponent from "@/views/login/login.vue"

export default {
    name: "productDetails",
    components: {
        LoginComponent
    },
    mixins: [productDetails]
}
</script>